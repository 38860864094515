import { useQuery } from '@apollo/client';

// import { UserVars } from '@fullstory/browser';
import { USER, UserData, UserVars } from '../../graphql/queries/user';
import { USER_PUBLIC_PAGE, UserPageData, UserPageVars } from '../../graphql/queries/userPage';

export type GetUserStatus = 'NONE' | 'LOADING' | 'NOT_FOUND' | 'FOUND';

export const useUser = () => {
  const {
    refetch: getUserById,
    data: userIdData,
    loading: userIdLoading,
  } = useQuery<UserData, UserVars>(USER, {
    fetchPolicy: 'standby',
  });

  const {
    refetch: getUserBySlug,
    data: userSlugData,
    loading: userSlugLoading,
  } = useQuery<UserPageData, UserPageVars>(USER_PUBLIC_PAGE, {
    fetchPolicy: 'standby',
  });

  return {
    getUserById,
    getUserBySlug,
    data: userIdData?.user || userSlugData?.userPage,
    loading: userIdLoading || userSlugLoading,
  };
};
