import mParticle from '@mparticle/web-sdk';
import { Box, Flex, Icon } from '@tyb-u/tyb-ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { logMparticleEvent } from 'src/utils/mparticle';

import UserWallet from '../../containers/UserWallet/UserWallet';
import { useUser } from '../../hooks/useUser';
import useWalletActivity from '../../hooks/useWalletActivity';
import { useAppSelector } from '../../redux/hooks';
import Loading from '../Loading';
import ContentModal from '../Modals/Content';

type UserDetailsPopoverProps = {
  visible: boolean;
  userId?: string;
  onClose: () => void;
};

export const UserDetailsPopover: React.FC<UserDetailsPopoverProps> = ({ visible, userId, onClose }) => {
  const [isOpen, setOpen] = useState(visible);
  const user = useAppSelector(({ user }) => user);
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const { activity, fetch: fetchActivity, loading } = useWalletActivity();

  const { getUserById, data } = useUser();

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, []);

  useMemo(() => {
    setOpen(visible);
  }, [visible]);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        const result = await getUserById({
          id: userId,
        });

        if (result?.data?.user) {
          fetchActivity(result.data.user.cChainPublicAddress);

          logMparticleEvent('view_wallet', mParticle.EventType.Other, {
            auth_status: authStatus.toLowerCase(),
            is_own: user && user.sub == result.data.user.id,
            user_id: result.data.user.id,
            user_slug: result.data.user.urlSlug,
            user_name: result.data.user.userName,
          });
        }
      })();
    }
  }, [isOpen]);

  return (
    <ContentModal
      fullScreen={true}
      isOpen={isOpen}
      onRequestClose={handleClose}
      body={
        !data ? (
          <Loading />
        ) : (
          <Box p={20} height="100%" width={['100%', '75%']}>
            <Flex flexDirection="row" alignItems="center" justifyContent="flex-end" mb="20px">
              <Box __css={{ cursor: 'pointer' }} ml={10} onClick={handleClose}>
                <Icon.BoxiconsRegular.XCircle size={28} />
              </Box>
            </Flex>
            <Flex flex={2} flexDirection="column" position="relative">
              <UserWallet user={data} wallet={data.userWallet} activity={activity} loading={loading} fullScreen />
            </Flex>
          </Box>
        )
      }
    />
  );
};
