import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

interface IFetchMethodOptions {
  take?: number;
  skip?: number;
}

type InfiniteScrollProps = {
  containerRef: any;
  total?: number;
  itemKey: string;
  items?: any[];
  itemPerFetch?: number;
  loadMore: (options?: IFetchMethodOptions) => Promise<any>;
  children: (items: any[]) => React.ReactElement;
};

export type InfiniteScrollRef = {
  appendItems: (page?: number) => void;
};

const InfiniteScroll: React.ForwardRefRenderFunction<InfiniteScrollRef, InfiniteScrollProps> = (props, ref) => {
  const { itemKey, items: propsItems, total, containerRef, itemPerFetch = 10, loadMore, children } = props;
  const [items, setItems] = useState<ReturnType<typeof loadMore>[]>([]);

  const appendItems = useCallback(async () => {
    const newItems = await loadMore({
      skip: items.length,
      take: itemPerFetch,
    });

    if (newItems) {
      setItems((state) => {
        const missing = newItems.filter((v) => !state.some((x) => v[itemKey] === x[itemKey]));
        if (missing.length > 0) {
          state.push(...missing);
        }

        return state;
      });
    }
  }, [itemKey, itemPerFetch, items.length, loadMore]);

  useImperativeHandle(ref, () => ({
    appendItems,
  }));

  const onScroll = useCallback(async () => {
    if (containerRef?.current) {
      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const scrolled = scrollHeight - scrollTop;
      const hasMore = total ? items.length < total : true;

      if (scrolled <= clientHeight + 50 && hasMore) {
        await appendItems();
      }
    }
  }, [containerRef, itemPerFetch, items.length, loadMore, total, appendItems]);

  useMemo(() => {
    if (propsItems && propsItems.length > 0) {
      setItems((state) => {
        const missing = propsItems.filter((v) => !state.some((x) => v[itemKey] === x[itemKey]));
        if (missing.length > 0) {
          state.push(...missing);
        }

        return state;
      });
    }
  }, [propsItems]);

  useEffect(() => {
    let timer;
    const debounce = () => {
      clearTimeout(timer);
      timer = setTimeout(onScroll, 10);
    };

    if (containerRef?.current) {
      containerRef.current.addEventListener('scroll', debounce);
    }

    return () => {
      containerRef?.current?.removeEventListener('scroll', debounce);
    };
  }, []);

  return children(items);
};

export default forwardRef(InfiniteScroll);
