import { Box, Button, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useRef } from 'react';
import { useTheme } from 'styled-components';

import ContentModal from '../../components/Modals/Content';
import UserAvatar from '../../components/UserAvatar';
import InfiniteScroll from '../../containers/InfiniteScroll';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { convertRemToPx } from '../../utils';

export interface UserInfiniteListModalProps {
  title: string;
  isOpen: boolean;
  users: {
    urlSlug: string;
    avatarUrl: string;
    userName: string;
    email?: string;
    cChainPublicAddress?: string;
  }[];
  total: number;
  onViewWallet: (userId: string) => void;
  onClose: () => void;
  loadMore: (take: number, skip: number) => void;
}

const UserInfiniteListModal: React.FC<UserInfiniteListModalProps> = ({
  title,
  isOpen,
  onClose,
  loadMore,
  onViewWallet,
  users,
  total,
}) => {
  const containerRef = useRef();

  const size = useWindowSize();
  const theme = useTheme();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const load = useCallback(async ({ take, skip }) => {
    return loadMore(take, skip);
  }, []);
  return (
    <ContentModal
      isOpen={isOpen}
      fullScreen={isMobile}
      onRequestClose={onClose}
      body={
        <Box p={20} minHeight="400px" overflowY="hidden" width={['100%', '500px']}>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            mb="20px"
            pb="10px"
            borderBottom="1px solid #ccc"
          >
            <Text variant="text4-400" fontSize="16px" fontWeight="bold" marginRight="30%">
              {title}
            </Text>

            <Box __css={{ cursor: 'pointer' }} ml={10} pt="5px" onClick={onClose}>
              <Icon.BoxiconsRegular.X size={28} />
            </Box>
          </Flex>

          <Box
            data-testid="infinite-scroll"
            ref={containerRef}
            height={isMobile ? '100%' : '450px'}
            overflowY="auto"
            mt="15px"
            pb={['60px', '10px']}
          >
            <InfiniteScroll
              containerRef={containerRef}
              itemKey="id"
              items={users}
              total={total}
              itemPerFetch={20}
              loadMore={load}
            >
              {(items) => (
                <>
                  {items.map((member) => (
                    <Flex
                      key={`user_${member.email}`}
                      flexDirection="row"
                      justifyContent="space-between"
                      mb="10px"
                      data-testid="team-member-user-row"
                    >
                      <Flex flexDirection="row">
                        <UserAvatar avatarUrl={member.avatarUrl} size={47} borderRadius={47} />
                        <Text ml="10px" mt="5px">
                          {member.userName || member.email}
                        </Text>
                      </Flex>

                      {member.cChainPublicAddress && (
                        <Button
                          variant="primary"
                          height="31px"
                          px="12px"
                          py="0px"
                          mr="10px"
                          onClick={() => onViewWallet(String(member.id))}
                        >
                          View Wallet
                        </Button>
                      )}
                    </Flex>
                  ))}
                </>
              )}
            </InfiniteScroll>
          </Box>
        </Box>
      }
    />
  );
};

export default UserInfiniteListModal;
