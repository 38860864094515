import { gql } from '@apollo/client';

import { IUser } from '../../interface/IUser';
import { IUserWallet } from '../../interface/IUserWallet';

export interface UserVars {
  id: string;
}

export interface UserData {
  user: IUser & { userWallet: IUserWallet };
}

export const USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      avatarUrl
      bio
      createdAt
      email
      firstName
      lastName
      userName
      xChainPublicAddress
      cChainPublicAddress
      repCardParticipationCount
      birthdate
      twitterUrl
      instagramUrl
      tiktokUrl
      urlSlug
      userWallet {
        address
        relatedContracts {
          type
          contractAddress
          collectibleExtraMetadatas {
            contractAddress
            tokenId
            description
            additionalDetails
          }
        }
        collectiblesSettings {
          contractAddress
          tokenId
          visibility
        }
      }
    }
  }
`;
