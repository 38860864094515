import { Box, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import * as label from 'numberlabel';
import { useEffect, useMemo, useState } from 'react';

import ImageCDN from '../ImageCDN';
import { UserDetailsPopover } from '../UserDetailsPopover';

export interface AvatarRowProps {
  list: { id: number | string; avatarUrl: string }[];
  total?: number;
  max: number;
  itemWidth: number;
  showList?: () => void;
  showUserId?: string;
  staticVersion?: boolean;
  onClose?: () => void;
}

export const AvatarRow: React.FC<AvatarRowProps> = ({
  list,
  max = 8,
  itemWidth = 64,
  showList,
  showUserId,
  total,
  staticVersion = false,
  onClose,
}) => {
  const isMiniature = itemWidth < 36;

  const itemMarginLeft = 15;

  if (!total) {
    total = list.length;
  }
  const [showUserPopUp, setShowUserPopUp] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>(showUserId);

  const handleUserPopUp = (userId: string) => {
    setSelectedUserId(userId);
    setShowUserPopUp(true);
  };

  const handleCloseUserPopUp = () => {
    setSelectedUserId(null);
    setShowUserPopUp(false);
    onClose && onClose();
  };

  const listModal = () => {
    if (showList) {
      showList();
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      handleUserPopUp(selectedUserId);
    } else {
      handleCloseUserPopUp();
    }
  }, [selectedUserId]);

  useEffect(() => setSelectedUserId(showUserId), [showUserId]);

  const diff = useMemo(() => total - max, [total, max]);

  const showMore = () => {
    let more = `${total}`;
    if (diff > 0) {
      more = `+${diff > 1000 ? label.convert(diff, 'symbol', { toFixed: 1 }) : diff}`;
    }
    return more;
  };

  return (
    <Flex width="100%" data-testid="all-avatar-rows">
      {showUserPopUp && (
        <UserDetailsPopover visible={showUserPopUp} userId={selectedUserId} onClose={() => handleCloseUserPopUp()} />
      )}
      {list
        .sort((a, b) => {
          const aValue = a.avatarUrl !== null ? 1 : 0;
          const bValue = b.avatarUrl !== null ? 1 : 0;
          return bValue - aValue;
        })
        .map((item, idx) => {
          const url = item.avatarUrl?.toLowerCase().includes('.mp4') ? null : item.avatarUrl;
          return (
            idx + 1 <= max && (
              <Box
                data-testid="avatar-row"
                __css={
                  !staticVersion
                    ? { cursor: 'pointer', '&:hover': { marginTop: '-10px', transition: 'margin-top 0.3s' } }
                    : null
                }
                key={item.id}
                onClick={() => !staticVersion && handleUserPopUp(String(item.id))}
              >
                {!!url && (
                  <ImageCDN
                    key={idx}
                    width={`${itemWidth}px`}
                    height={`${itemWidth}px`}
                    variant="avatar"
                    src={url}
                    __css={{
                      bg: 'white',
                      marginLeft: idx === 0 ? '0px' : `-${itemMarginLeft}px`,
                      borderWidth: '1px',
                      borderColor: 'white',
                      borderStyle: 'solid',
                    }}
                    resizeTo="thumbnail"
                  />
                )}
                {!url && (
                  <Flex
                    key={idx}
                    justifyContent="center"
                    alignItems="center"
                    width={`${itemWidth}px`}
                    height={`${itemWidth}px`}
                    borderRadius="100%"
                    __css={{
                      bg: 'gray02',
                      marginLeft: idx === 0 ? '0px' : `-${itemMarginLeft}px`,
                      borderWidth: '1px',
                      borderColor: 'white',
                      borderStyle: 'solid',
                    }}
                  >
                    <Box color="white">
                      <Icon.BoxiconsRegular.User size={itemWidth * 0.7} />
                    </Box>
                  </Flex>
                )}
              </Box>
            )
          );
        })}
      {!isMiniature ? (
        <>
          {list.length > max && (
            <Flex
              data-testid="avatar-row-total"
              justifyContent="center"
              alignItems="center"
              width={`${itemWidth}px`}
              height={`${itemWidth}px`}
              bg="white"
              position="relative"
              __css={{
                cursor: 'pointer',
                borderWidth: '1px',
                borderColor: 'gray02',
                borderStyle: 'solid',
                borderRadius: '100%',
                marginLeft: `-${itemMarginLeft}px`,
              }}
              onClick={() => !staticVersion && listModal()}
            >
              <Text fontSize={`${itemWidth / 50}rem`}>{showMore()}</Text>
              {/* <GeneratedDataWarehouseInfo position="absolute" right={-2} top={-8} iconSize={15} /> */}
            </Flex>
          )}
        </>
      ) : (
        <Flex alignItems="center">
          {total > max ? (
            <Text variant="text6-400" ml="2">
              {showMore()} follower{diff > 0 ? '' : 's'}
            </Text>
          ) : (
            <Text variant="text6-400" ml="1">
              {showMore()} follower{diff > 0 ? '' : 's'}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
};
